import moment from 'moment';

export default {
  props: {
    graphAVG: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      creatingData: false,
      graphData: [],
    };
  },
  watch: {
    graphAVG: {
      handler() {
        if (!this.creatingData) this.createData();
      },
      deep: true,
    },
  },
  computed: {
    timeRange() {
      return this.$store.state.selections.time.range;
    },
  },
  methods: {
    getLabelBasedOnTimeRange() {
      if (this.timeRange === 'day') return 'dddd DD [•] HH[H]mm';
      else if (this.timeRange === 'year') return 'dddd DD MMMM GGGG';
      else if (this.timeRange === 'month') return 'dddd DD MMMM GGGG [•] HH[H]';
      else return 'dddd DD MMMM [•] HH[H]mm';
    },
    createData() {
      this.data.forEach(x => {
        x.value = this.global && this.global.profiles ? this.global.profiles[x.name] : undefined;
      });
      this.graphData = [];
      if (!this.graphAVG) return;
      this.creatingData = true;
      Object.keys(this.graphAVG).forEach(s => {
        const sensor = this.data.find(x => x.name === s);
        if (sensor) {
          const newGraph = {
            name: `${sensor.label} (${sensor.unit})`,
            unit: sensor.unit,
            values: {
              labels: Object.keys(this.graphAVG[s]).map(time => {
                return (time = moment(time)
                  .local()
                  .format(this.getLabelBasedOnTimeRange()));
              }),
              values: [],
            },
          };
          Object.keys(this.graphAVG[s]).forEach(x => {
            newGraph.values.values.push(this.graphAVG[s][x]);
          });
          this.graphData.push(newGraph);
        }
      });
      this.creatingData = false;
    },
  },
};
