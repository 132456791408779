<template>
  <div class="graph-card grid-tile">
    <div class="graph-card__header">
      <label class="grid-tile__label">
        {{ title }}
      </label>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GraphCard',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.graph-card
  margin-bottom $border-thick
  max-width 400px
  .graph-card__header
    margin-bottom $space-2
</style>
