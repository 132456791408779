import { mapState } from 'vuex';
import simpleheat from './simpleheat.js';
import chroma from 'chroma-js';

export default {
  props: {
    values: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      heatmaps: [],
      radius: 80,
      blur: 100,
      currentHeatmaps: [],
      creatingHeatmap: false,
    };
  },
  computed: {
    ...mapState('ui', {
      colorScales: state => state.colorScales,
    }),
    currentTimelineCursorDate() {
      return this.$store.state.selections.time.timeline;
    },
    currentFloors() {
      return this.$store.state.building.selectedBuilding.next || null;
    },
    liveMode() {
      return this.$store.state.selections.time.range === 'live';
    },
    selectedBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    currentOapp() {
      return this.$store.state.oapp.currentOapp;
    },
    currentColorScale() {
      return this.colorScales[this.currentOapp.type];
    },
    colorScale() {
      return chroma
        .scale(this.currentColorScale)
        .domain([this.currentOapp.limits[this.currentOapp.type].min, this.currentOapp.limits[this.currentOapp.type].max]);
    },
    canvasReady() {
      const canvas = document.getElementById(`canvas${0}`);
      return canvas ? true : false;
    },
  },
  watch: {
    canvasReady(isReady) {
      if (!isReady) return;
      else this.fillCanvas();
    },
    async selectedBuilding() {
      if (!this.selectedBuilding) {
        this.destroyHeatmaps();
        this.heatmaps = {};
      } else {
        await this.fillCanvas();
      }
    },
    'currentOapp.id'() {
      if (this.currentOapp === undefined) {
        this.destroyHeatmaps();
        this.heatmaps = {};
      }
    },
    async currentTimelineCursorDate(currentCursorDate) {
      if (!currentCursorDate) {
        this.destroyHeatmaps();
        this.heatmaps = {};
      }
      await this.fillCanvas();
    },
    selectedType() {
      this.destroyHeatmaps();
    },
    values: {
      async handler() {
        await this.fillCanvas();
      },
      deep: true,
    },
  },
  methods: {
    createRadiusAndBlur() {
      if (
        this.selectedBuilding.metadata.preferences &&
        this.selectedBuilding.metadata.preferences.heatmap &&
        this.selectedBuilding.metadata.preferences.heatmap &&
        this.selectedBuilding.metadata.preferences.heatmap.radius &&
        this.selectedBuilding.metadata.preferences.heatmap.blur
      ) {
        this.radius = this.selectedBuilding.metadata.preferences.heatmap.radius;
        this.blur = this.selectedBuilding.metadata.preferences.heatmap.blur;
      } else {
        const centerpoint = this.selectedBuilding.metadata.concave_hull.center_point[0];
        this.radius = (80 * 500) / centerpoint;
        this.blur = this.radius * 1.2;
      }
    },
    createHeatMap(floorId, data = []) {
      const canvas = document.getElementById(`canvas${floorId}`);
      const floor = document.getElementById(`floor${floorId}`);

      const heat = simpleheat(canvas);
      heat.max(this.currentOapp.limits[this.currentOapp.type].max);
      heat.radius(this.radius, this.blur);
      heat.colorScale(this.colorScale);
      const svgData = [];
      data.forEach(p => {
        const point = this.svgToScreenXY(p[0], p[1], floor.firstElementChild);
        svgData.push([point.x, point.y, p[2]]);
      });
      heat.data(svgData);
      return { floorId, heatmap: heat, data };
    },
    svgToScreenXY(x, y, svg) {
      const point = svg.createSVGPoint();
      const matrix = svg.getCTM();
      point.x = x;
      point.y = y;
      return point.matrixTransform(matrix);
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    createAllFloorsLastHeatmaps(values) {
      this.createRadiusAndBlur();
      this.heatmaps = [];
      this.currentFloors.forEach((f, idx) => {
        const floorValues = values.filter(v => v.floorUuid === f.uuid);
        if (floorValues.length) {
          const heat = this.createHeatMap(idx, floorValues.map(v => (v = [v.device.x, v.device.y, v.device.value])));
          this.heatmaps.push(heat);
        }
      });
    },
    createAllFloorsHeatmap(values) {
      this.createRadiusAndBlur();
      this.heatmaps = {};
      Object.keys(values).forEach(time => {
        this.currentFloors.forEach((f, idx) => {
          const floorValues = values[time].filter(v => v.floorUuid === f.uuid);
          if (floorValues.length) {
            const heat = this.createHeatMap(idx, floorValues.map(v => (v = [v.device.x, v.device.y, v.device.value])));
            if (!this.heatmaps[time]) this.heatmaps[time] = [];
            this.heatmaps[time].push(heat);
          }
        });
      });
    },

    destroyHeatmaps() {
      if (!this.currentHeatmaps) return;
      this.currentHeatmaps.forEach(h => {
        const canvas = document.getElementById(`canvas${h.floorId}`);
        if (canvas) canvas.getContext('2d').clearRect(0, 0, canvas.clientWidth, canvas.height);
      });
      this.currentHeatmaps = [];
    },
    addPoint(floorId, point) {
      const heat = this.heatmaps.find(h => {
        h.floorId === floorId;
      });
      if (heat) heat.add(point);
    },
    updateData(floorId, data) {
      const heatmap = this.heatmaps.find(h => h.floorId === floorId);
      if (heatmap) {
        const heat = heatmap.heatmap;
        heat.clear();
        heat.data(data);
        heat.draw();
      }
    },
    fillCanvas() {
      try {
        if (!this.selectedBuilding || this.selectedType !== 'heatmap-rainbow' || this.creatingHeatmap) return;
        this.creatingHeatmap = true;
        this.destroyHeatmaps();
        if (this.values && Object.keys(this.values).length) {
          // while (!this.canvasReady()) {
          //   await this.sleep(500);
          // }
          if (!this.liveMode) {
            if (!this.heatmaps[this.currentTimelineCursorDate]) {
              const valuesForHeatmap = {};
              Object.keys(this.values).map(time =>
                Object.keys(this.values[time].devices).map(floor =>
                  Object.keys(this.values[time].devices[floor]).map(space =>
                    Object.keys(this.values[time].devices[floor][space]).map(device => {
                      if (!valuesForHeatmap[time]) valuesForHeatmap[time] = [];
                      valuesForHeatmap[time].push({ device: this.values[time].devices[floor][space][device], floorUuid: floor });
                    })
                  )
                )
              );
              if (this.selectedBuilding) {
                this.createAllFloorsHeatmap(valuesForHeatmap);
                if (this.heatmaps[this.currentTimelineCursorDate]) {
                  this.heatmaps[this.currentTimelineCursorDate].forEach(heatmap => {
                    heatmap.heatmap.draw();
                  });
                }
                this.currentHeatmaps = this.heatmaps[this.currentTimelineCursorDate];
              }
            } else {
              if (this.selectedBuilding) {
                if (this.heatmaps[this.currentTimelineCursorDate]) {
                  this.heatmaps[this.currentTimelineCursorDate].forEach(heatmap => {
                    heatmap.heatmap.draw();
                  });
                }
                this.currentHeatmaps = this.heatmaps[this.currentTimelineCursorDate];
              }
            }
          } else {
            const valuesForHeatmap = [];
            Object.keys(this.values.devices).map(floor =>
              Object.keys(this.values.devices[floor]).map(space =>
                Object.keys(this.values.devices[floor][space]).map(device => {
                  valuesForHeatmap.push({ device: this.values.devices[floor][space][device], floorUuid: floor });
                })
              )
            );
            this.createAllFloorsLastHeatmaps(valuesForHeatmap);
            this.heatmaps.forEach(heatmap => {
              heatmap.heatmap.draw();
            });
            this.currentHeatmaps = this.heatmaps;
          }
        }
        this.creatingHeatmap = false;
      } catch (error) {
        this.creatingHeatmap = false;
        this.destroyHeatmaps();
      }
    },
  },
};
