<template>
  <Graph :chartData="chartData"
         :options="options"
         :height="250"
         class="graph-sidebar" />
</template>

<script>
import Graph from './graph.vue';

export default {
  name: 'GraphSidebar',
  components: {
    Graph,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    unit: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      options: {
        animation: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              maxBarThickness: 40,
              gridLines: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                callback: value => {
                  return value;
                },
              },
            },
          ],
        },
        tooltips: {
          backgroundColor: '#464749',
          titleFontColor: 'white',
          bodyFontColor: 'white',
          displayColors: false,
          mode: 'index',
          intersect: false,
          callbacks: {
            label: item => {
              return `${item.yLabel.toFixed(2)} ${this.unit ? this.unit : ''}`;
            },
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      return {
        labels: this.data.labels,
        datasets: [
          {
            data: this.data.values,
            borderColor: 'black',
            pointStyle: 'circle',
            pointBackgroundColor: 'black',
            radius: 2,
          },
        ],
      };
    },
  },
};
</script>

<style lang="stylus" scoped>
.graph-sidebar
  width 100%
</style>