'use strict';

export default simpleheat;

function simpleheat(canvas) {
  if (!(this instanceof simpleheat)) return new simpleheat(canvas);

  this._canvas = canvas = typeof canvas === 'string' ? document.getElementById(canvas) : canvas;

  this._ctx = canvas.getContext('2d');
  this._width = canvas.width;
  this._height = canvas.height;

  this._max = 1;
  this._data = [];
  this._grads = [];
}

simpleheat.prototype = {
  defaultRadius: 25,

  data: function(data) {
    this._data = data;
    return this;
  },

  max: function(max) {
    this._max = max;
    return this;
  },

  add: function(point) {
    this._data.push(point);
    return this;
  },

  clear: function() {
    this._data = [];
    return this;
  },

  radius: function(r, blur) {
    blur = blur === undefined ? 15 : blur;
    this._blur = blur;
    this._radius = r;

    this._r = r + blur;
    return this;
  },

  resize: function() {
    this._width = this._canvas.width;
    this._height = this._canvas.height;
  },

  colorScale: function(colorScale) {
    this._colorScale = colorScale;

    return this;
  },

  draw: function(minOpacity) {
    const ctx = this._ctx;

    ctx.clearRect(0, 0, this._width, this._height);

    // draw a grayscale heatmap by putting a blurred circle at each data point
    for (let i = 0; i < this._data.length; i++) {
      const p = this._data[i];
      ctx.globalAlpha = Math.max(p[2] / this._max, minOpacity === undefined ? 0.05 : minOpacity);
      const radgrad = ctx.createRadialGradient(p[0], p[1], this._r / 3, p[0], p[1], this._r);
      radgrad.addColorStop(0, this._colorScale(p[2]));
      radgrad.addColorStop(1, 'rgba(255,255,255,0)');

      ctx.fillStyle = radgrad;
      ctx.fillRect(0, 0, this._width, this._height);
    }

    return this;
  },

  _createCanvas: function() {
    if (typeof document !== 'undefined') {
      return document.createElement('canvas');
    } else {
      // create a new canvas instance in node.js
      // the canvas class needs to have a default constructor without any parameter
      return new this._canvas.constructor();
    }
  },
};
